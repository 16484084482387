import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Icon from "../../../../components/icon";
import ArrowRightIcon from "../../../../components/svg/arrowRightIcon";
import Input from "../../../../components/input";
import Image from "../../../../components/image";
import ViewBreakupCard from "../../../../components/viewBreakupCard";
import SelectStyle from "../../../../components/input/selectStyle";
import OptionSelectItem from "../../../../components/optionSelectItem";
import INRIcon from "../../../../assets/img/icon/inr-icon.svg";
import USDTIcon from "../../../../assets/img/icon/usdt-icon.png";
import BTCIcon from "../../../../assets/img/icon/btc-icon.png";
import ETCIcon from "../../../../assets/img/icon/etc-icon.png";
import USDCIcon from "../../../../assets/img/icon/usdc-icon.png";
import useHome from "../../hooks/home.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBuyData,
  saveNetworkFee,
  saveBuyReceiveData,
} from "../../../../redux/deposit/persistReducer";
import {
  getExchangeRatesFetch,
  getPlatformFeeFetch,
} from "../../../../redux/user/reducers";
import { saveCurrency } from "../../../../redux/user/persistReducer";
import { truncate } from "../../../../utils/util";
import useDebounce from "../../../../hooks/useDebounce";
import { APPLICATION_ID } from "../../../../utils/constants";

const currencyOption = [
  {
    value: "INR",
    label: (
      <OptionSelectItem src={INRIcon} text="INR" subText="Tether" min="100" />
    ),
  },
  {
    value: "USDT",
    label: (
      <OptionSelectItem src={USDTIcon} text="USDT" subText="Tether" min="100" />
    ),
  },
  {
    value: "BTC",
    label: (
      <OptionSelectItem src={BTCIcon} text="BTC" subText="Bitcoin" min="1" />
    ),
  },
  {
    value: "ETC",
    label: (
      <OptionSelectItem
        src={ETCIcon}
        text="ETC"
        subText="Ethereum Classic"
        min="20"
      />
    ),
  },
  {
    value: "USDC",
    label: (
      <OptionSelectItem
        src={USDCIcon}
        text="USDC"
        subText="USD Coin"
        min="100"
      />
    ),
  },
];

const BuyForm = (props) => {
  const dispatch = useDispatch();

  const { exchangeRateBuy, platformFee, platformCurrencies, profileDetails, markUp, isLoading, applicationId } =
    useSelector((state) => state?.userReducer);

  const { buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );
  const feeWavier = profileDetails?.userInfo?.feeWaiver

  const payonrampFee = !feeWavier ? platformFee?.buyFeePercent : 0
  const {
    isViewBreakupToggle,
    onViewBreakupToggle,
    selectedBuyCurrency,
    handleBuyCurrencyChange,
  } = useHome();

  useEffect(() => {
    dispatch(getPlatformFeeFetch());
    if (
      !buyReceiveData?.token ||
      buyReceiveData?.token !== props.selectedCurrency
    ) {
      const selectedReceiveCurrency = {
        label: (
          <OptionSelectItem
            text={reqCurrencyObj?.currency}
            subText={reqCurrencyObj?.tokenName}
            code={reqCurrencyObj?.tokenType}
          />
        ),
        value: reqCurrencyObj?.blockchain,
        token: reqCurrencyObj?.currency,
        fee: reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee,
        networkFee: reqCurrencyObj?.networkFee,
        code: reqCurrencyObj?.tokenType,
        name: reqCurrencyObj?.aliasName,
      };
      dispatch(saveBuyReceiveData(selectedReceiveCurrency));
    }
  }, []);

  const [originalAmount, setOriginalAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [marginAmount, setMarginAmount] = useState(0);
  const [minValue, setMinValue] = useState("");
  const [active, setActive] = useState(false)

  const getConvertedAmount = () => {
    if (marginAmount) {
      const convertedValue = truncate(
        parseFloat(
          (formik.values.buyCurrencyAmount -
            formik.values.buyCurrencyAmount *
            (payonrampFee || 0) *
            0.01 -
            propsNetworkFee * marginAmount) /
          marginAmount
        )
      );
      const nonNegativeConvertedAmount = Math.max(convertedValue, 0); // Ensure non-negative value
      setConvertedAmount(nonNegativeConvertedAmount);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const receiveAmount = useCallback((amount) => {
    getConvertedAmount(amount);
  });

  useEffect(() => {
    receiveAmount(originalAmount);
  }, [originalAmount, receiveAmount]);

  const reqCurrencyObj = platformCurrencies?.currecies[0];

  let selectedFee = props.selectedFee ? truncate(props.selectedFee) : truncate(
    reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee
  );
  let propsCode = props.code ? props.code : reqCurrencyObj?.tokenType;
  let propsNetworkFee = props.networkFee ? props.networkFee : reqCurrencyObj?.networkFee;

  useEffect(() => {
    setMinValue(Math.ceil((selectedFee - propsNetworkFee) * parseFloat(exchangeRateBuy) + parseFloat(exchangeRateBuy * parseFloat(markUp))));
    setMarginAmount(parseFloat(exchangeRateBuy))
  }, [exchangeRateBuy, propsCode]);

  const formik = useFormik({
    initialValues: {
      buyCurrencySelect: "INR",
      buyCurrencyAmount: "",
    },
    validationSchema: Yup.object({
      buyCurrencySelect: Yup.string().required("Please enter a select"),
      buyCurrencyAmount: Yup.number()
        .moreThan(minValue, "min amount is " + (minValue + 1))
        .max(500000, "max amount is 500000")
        .required("Please enter a buy amount"),
    }),
    onSubmit: (values) => {
      dispatch(saveBuyData(values));
      dispatch(
        saveNetworkFee({
          totalSent: values.buyCurrencyAmount,
          network: propsNetworkFee * marginAmount,
          payonrampFee:
            values.buyCurrencyAmount * (payonrampFee * 0.01),
          total:
            values.buyCurrencyAmount -
            values.buyCurrencyAmount * (payonrampFee * 0.01) -
            propsNetworkFee * marginAmount,
          price: marginAmount
        })
      );
      dispatch(saveCurrency(convertedAmount));
      props.onOpenModal();
      setConvertedAmount("0");
      formik.resetForm();
    },
  });

  const debouncedHandleChange = useDebounce((value) => {
    receiveAmount(value);
    setOriginalAmount(value);
    dispatch(
      getExchangeRatesFetch({
        values: {
          currency: props.selectedCurrency,
          source: props.source,
          blockchain: props.blockchain,
          quantity: value ? value : "1",
          decimals: props.decimals,
          applicationId : applicationId || APPLICATION_ID
        },
      })
    );
    setActive(false)
  }, 700); // 0.7s debounce delay

  const handleChange = (event) => {
    setActive(true)
    const value = event?.target?.value;
    formik.handleChange("buyCurrencyAmount")(value);
    debouncedHandleChange(value);
  };

  useEffect(() => {
    if (props.selectedCurrency) {
      dispatch(
        getExchangeRatesFetch({ values: { currency: props.selectedCurrency, source: props.source, blockchain: props.blockchain, quantity: originalAmount ? originalAmount : "1", decimals: props.decimals, applicationId : applicationId || APPLICATION_ID} })
      );
    }
  }, [props.selectedCurrency, props.blockchain]);
  return (
    <>
      <Flex flexColumn justifyContent="center">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-field">
            <div className="row d-flex align-items-center">
              <div className="col-5 col-md-5">
                <div className="form-box">
                  <SelectStyle
                    mb0
                    secondary
                    isSearchable={true}
                    name="buyCurrencySelect"
                    value={selectedBuyCurrency[0]}
                    defaultValue={currencyOption[0]}
                    onChange={(selectedOption) => {
                      handleBuyCurrencyChange(selectedOption);
                      formik.handleChange("buyCurrencySelect")(
                        selectedOption.value
                      );
                    }}
                    options={currencyOption}
                    errormessage={
                      formik.touched.buyCurrencySelect &&
                        formik.errors.buyCurrencySelect
                        ? formik.errors.buyCurrencySelect
                        : null
                    }
                    isDisabled
                  />
                </div>
              </div>
              <div className="col-7 col-md-7">
                <div className="card-field-form-box">
                  <label className="card-field-label">
                    {formik.values.buyCurrencySelect === "INR" && (
                      <Image src={INRIcon} alt="INR" />
                    )}
                    {formik.values.buyCurrencySelect === "USDT" && (
                      <Image src={USDTIcon} alt="USDT" />
                    )}
                    {formik.values.buyCurrencySelect === "BTC" && (
                      <Image src={BTCIcon} alt="BTC" />
                    )}
                    {formik.values.buyCurrencySelect === "ETC" && (
                      <Image src={ETCIcon} alt="ETC" />
                    )}
                    {formik.values.buyCurrencySelect === "USDC" && (
                      <Image src={USDCIcon} alt="USDC" />
                    )}
                    <Text
                      type="SPAN"
                      className="ml-8"
                      text={formik?.values?.buyCurrencySelect}
                    />
                  </label>
                  <Input
                    secondary
                    mb0
                    noLabel
                    type="number"
                    name="buyCurrencyAmount"
                    placeholder={minValue ? `min ${minValue + 1}` : ""}
                    value={formik?.values?.buyCurrencyAmount}
                    onChange={handleChange}
                    mandatory
                    errormessage={
                      formik.touched.buyCurrencyAmount &&
                        formik.errors.buyCurrencyAmount
                        ? formik.errors.buyCurrencyAmount
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Flex justifyContent="center" className="mb-4">
            <Icon gray icon={<ArrowRightIcon down />} />
          </Flex>
          <div className="card-field bg-light-green">
            <div className="row d-flex align-items-center">
              <div className="col-5 col-md-5">
                <div className="form-box">
                  <Button
                    mb0
                    secondary
                    isSearchable={true}
                    text={
                      <>
                        <span className="singleLine">
                          {props.selectedCurrency}
                          {props.code !== null && <br />}
                          {props.code !== null && `on ${propsCode}`}{" "}
                        </span>
                        <span className="arrow-icon">
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                          >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                          </svg>
                        </span>
                      </>
                    }
                    type="button"
                    className="custom-button buy-btn"
                    onClick={props.onClick}
                  />
                </div>
              </div>
              <div className="col-7 col-md-7">
                <div className="card-field-form-box">
                  <label className="card-field-label">
                    {props.selectedCurrency}
                  </label>
                  <Input
                    secondary
                    mb0
                    noLabel
                    type="text"
                    name="receiveCurrencyAmount"
                    placeholder="You will receive"
                    value={convertedAmount}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    readonly={true}
                  />
                </div>
                <Flex flexColumn className="mt-12">
                  <Text
                    type="PARAGRAPH"
                    className="font-size-12 font-weight-500 mb-0"
                    text={`1 ${props.selectedCurrency} = ${isLoading ? "Calculating..." : `₹ ${exchangeRateBuy ? marginAmount : 0}`}`}
                  />
                </Flex>
              </div>
            </div>
          </div>
          <Flex flexColumn>
            <Button
              type="submit"
              text={isLoading ? "Calculating..." : "Proceed"}
              className="mb-3"
              disabled={minValue + 1 > formik?.values?.buyCurrencyAmount || isLoading || active}
            />
            <Button
              type="button"
              linkDark
              text={isViewBreakupToggle ? "Hide Breakup" : "View Breakup"}
              onClick={onViewBreakupToggle}
            />
            {isViewBreakupToggle && (
              <ViewBreakupCard
                active={isViewBreakupToggle}
                lists={[
                  {
                    text: `${formik.values.buyCurrencySelect + " Net Amount"}`,
                    number:
                      isLoading ? "Calculating..." :
                        isNaN(formik.values.buyCurrencyAmount) ||
                          isNaN(payonrampFee) ||
                          isNaN(propsNetworkFee) ||
                          isNaN(exchangeRateBuy) ||
                          formik.values.buyCurrencyAmount <= 0
                          ? "₹0.0000"
                          : `₹${truncate(
                            formik.values.buyCurrencyAmount -
                            formik.values.buyCurrencyAmount *
                            (payonrampFee || 0) *
                            0.01 -
                            propsNetworkFee * marginAmount
                          )}`,

                  },
                  {
                    text: "PayOnRamp Fee (incl. GST)",
                    number: isLoading ? "Calculating..." : `₹${(formik.values.buyCurrencyAmount > 0
                      ? truncate(
                        formik.values.buyCurrencyAmount *
                        payonrampFee *
                        0.01
                      )
                      : "0.0000")
                      }`,
                  },
                  {
                    text: "Network Fee",
                    number:
                      isLoading ? "Calculating..." : isNaN(propsNetworkFee) ||
                        isNaN(marginAmount) ||
                        formik.values.buyCurrencyAmount <= 0
                        ? "₹0.0000"
                        : `₹${truncate(propsNetworkFee * marginAmount)}`,
                  },
                  {
                    text: "",
                    number: `(${propsNetworkFee} ${props.selectedCurrency})`,
                  },
                  {
                    text: "Total",
                    number: `₹${formik.values.buyCurrencyAmount || 0}`,
                  },
                ]}
              />
            )}
          </Flex>
        </form>
      </Flex>
    </>
  );
};

export default BuyForm;
